import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import axios from "axios";
import * as Vibrant from "node-vibrant";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {Button, Nav, NavItem} from "react-bootstrap";
import * as moment from "moment";

export default class Login extends Component {
    constructor(props) {
        super(props);

        let logged = Session.getItem("logged");
        if(String(props.location.search).includes('logout')) { logged = "false"; }

        let endpoint = "";
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            //endpoint = "/offeris/";
             endpoint = "https://gpr.offeris.com/gpr/";
        } else {
            endpoint = "https://" + this.props.match.params.instance + ".offeris.com/" + this.props.match.params.instance + "/";
        }

        this.endpoint = endpoint;
        this.vibrant = Vibrant.from(endpoint + "PictureServlet?id=" + this.props.match.params.idMandant + "&action=GET_MANDANT_LOGO");


        this.state = {
            settings:{},
            loaded:false,
            logged: logged,
            fields: {
                login: "",
                password: ""
            },
            error: false,
            errorMsg: "",
            // colour: "#EC407A"
            colour: "#FFFFFF",
            selectedTabKey: 1
        };

        this.vibrant.getSwatches().then((swatches) => {
            this.setState({colour: swatches.Vibrant.getHex()});
        }).catch(e => {
            //console.log(e);
        });
    }

    async componentDidMount() {
        document.getElementById("favicon").href =Session.getItem("endpoint") + "PictureServlet?id=" + Session.idMandant + "&action=GET_MANDANT_LOGO";
        await Session.initStorage(this.props.match.params.instance, this.props.match.params.idMandant);
        let selectedTabKey = Number(Session.getItem("selectedLoginTab")) || 1;

        if(this.state.logged === "false"){
            PT.purgeLogin();
        }

        if (this.endpoint !== Session.getItem("endpoint")) {
            Session.setItem("endpoint", this.endpoint)
        }
        let userLang = navigator.language || navigator.systemLanguage;
        //Session.setItem("langIsoCode", userLang.substring(0, 2).toUpperCase());
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: "0",
                idContact: "0",
                action: "GET_PORTAL_SETTINGS"

            }
        })
            .then(async (response) => {
                Session.setItem("idLanguage", response.data.portal_language_id);
                ST.clean();
                await ST.init();
                //console.log(response.data);
                this.setState({
                    loaded: true,
                    settings: response.data,
                    selectedTabKey
                });
                window.portalSettings = response.data;
                this.setLang();
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });

    }

    setLang(){
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idLanguage: Session.getItem("idLanguage"),
                action: "GET_PORTAL_LANG_ISO"
            }
        }).then((response) => {
            moment.locale(response.data.lang);
            document.documentElement.lang=response.data.lang;
        }).catch((error) => {
            PT.handleError(error, this.props.history);
        });
    }

    loginCustomer = () => {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idMandant: Session.idMandant,
            login: this.state.fields.login,
            password: this.state.fields.password,
            action: "PORTAL_LOGIN"
        },{headers: {'Content-type': 'application/json; charset=utf-8',
            }})
            .then((response) => {
                let {idContact, idCompany, idLanguage, idDealer, idCurrency, codeCurrency, priceLevel, contactName, companyName, contactGender} = response.data;
                if (idContact !== "") {
                    Session.setItem("logged", "true");
                    Session.setItem("dealerLogin", "false");
                    Session.setItem("idContact", idContact);
                    Session.setItem("idCompany", idCompany);
                    Session.setItem("idLanguage", idLanguage);
                    Session.setItem("idDealer", idDealer);
                    Session.setItem("idCurrency", idCurrency);
                    Session.setItem("codeCurrency", codeCurrency.replace(/eur/ig, "€"));
                    Session.setItem("priceLevel", priceLevel);
                    Session.setItem("contactName", contactName);
                    Session.setItem("contactGender", contactGender);
                    Session.setItem("companyName", companyName);
                    if (window.cordova) {
                        //2 tyzdne
                        Session.setItem("logoutTimer", Date.now() + 1000 * 3600 * 24 * 14);
                    } else {
                        Session.setItem("logoutTimer", Date.now() + 30 * 60000);
                    }

                    Session.removeItem("langIsoCode");
                    ST.clean();
                    ST.init();

                    this.setState({logged: true}, () => this.props.history.replace(Session.getItem("basename") + "/"));
                } else {
                    this.setState({error: true});
                    this.setState({errorMsg: ST.get("login_wrong", "global")});
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    };

    loginDealer = () => {
            axios.post(Session.getItem("endpoint") + 'PortalServlet', {
                idMandant: Session.idMandant,
                login: this.state.fields.login,
                password: this.state.fields.password,
                action: "PORTAL_LOGIN_DEALER"
            },{headers: {'Content-type': 'application/json; charset=utf-8',
                }})
                .then((response) => {
                    let {dealerName, idDealer, idCurrency, codeCurrency, idLanguage, priceLevel} = response.data;
                    if (idDealer !== "") {
                        Session.setItem("logged", "true");
                        Session.setItem("dealerLogin", "true");
                        Session.setItem("dealerName", dealerName);
                        Session.setItem("idLanguage", idLanguage);
                        Session.setItem("idContact", "");
                        Session.setItem("idDealer", idDealer);
                        Session.setItem("idCurrency", idCurrency);
                        Session.setItem("codeCurrency", codeCurrency.replace(/eur/ig, "€"));
                        Session.setItem("priceLevel", priceLevel);
                        if (window.cordova) {
                            //2 tyzdne
                            Session.setItem("logoutTimer", Date.now() + 1000 * 3600 * 24 * 14);
                        } else {
                            Session.setItem("logoutTimer", Date.now() + 30 * 60000);
                        }

                        Session.removeItem("langIsoCode");
                        ST.clean();
                        ST.init();

                        this.setState({logged: true}, () => this.props.history.replace(Session.getItem("basename") + "/"+window.portalSettings.portal_dealer_home));
                    } else {
                        this.setState({error: true});
                        this.setState({errorMsg: ST.get("login_wrong", "global")});
                    }
                })
                .catch((error) => {
                    //console.log(error);
                })
    };

    login = () => {
        if (this.state.fields.login !== "" && this.state.fields.password !== "") {
            if(this.state.selectedTabKey === 2) {
                this.loginDealer();
            } else {
                this.loginCustomer();
            }
            //PT.resetBasket();
        } else {
            this.setState({error: true});
            this.setState({errorMsg: ST.get("global_mandatory_not_complete", "global")});
        }
    };

    changeInput = (value, event) => {
        let {fields, error} = this.state;

        fields[value] = event.target.value;
        this.setState({fields});
        if (error) {
            this.setState({error: false});
        }
    };

    maybeSubmit = (event) => {
        if (event.keyCode === 13) {
            this.login();
        }
    };

    handleTabChange = (key) => {
        Session.setItem("selectedLoginTab", key);
        this.setState({
            selectedTabKey: key
        })
    };

    render() {
        let {logged, error, errorMsg, selectedTabKey} = this.state;
        let className = "";
        if (logged === "true") {
            return (<Redirect to={Session.getItem("basename")}/>);
        } else {
            if (error) {
                className = "form-control border-warning-300";
            } else {
                className = "form-control";
            }
            return (
                <div className="page-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="panel panel-body login-form">
                                <div className="text-center">
                                    <div>
                                        <img
                                            src={Session.getItem("endpoint") + "PictureServlet?id=" + Session.idMandant + "&action=GET_MANDANT_LOGO"}
                                            className="img-responsive center-block" alt="mandant_logo"/>
                                    </div>
                                    <h5 className="content-group">{ST.get("login_login", "global")}</h5>
                                </div>

                                <Nav bsStyle={"tabs"} activeKey={selectedTabKey} onSelect={this.handleTabChange} justified>
                                    <NavItem eventKey={1}>
                                        <b>{ST.get("customer", "global")}</b>
                                    </NavItem>
                                    <NavItem eventKey={2}>
                                        <b>{ST.get("dealer", "global")}</b>
                                    </NavItem>
                                </Nav>

                                <div className="form-group has-feedback has-feedback-left mt-10">
                                    <input type="text" name="loginName" id="loginName" className={className}
                                           placeholder={ST.get("intrauser_login_name", "global")}
                                           value={this.state.fields.login}
                                           onChange={this.changeInput.bind(this, "login")}
                                           onKeyUp={this.maybeSubmit.bind("this")}
                                    />
                                    <div className="form-control-feedback">
                                        <i className="icon-user text-muted"/>
                                    </div>
                                </div>

                                <div className="form-group has-feedback has-feedback-left">
                                    <input type="password" name="loginPassword" id="loginPassword"
                                           className={className}
                                           placeholder={ST.get("intrauser_password", "global")}
                                           value={this.state.fields.password}
                                           onChange={this.changeInput.bind(this, "password")}
                                           onKeyUp={this.maybeSubmit.bind("this")}
                                    />
                                    <div className="form-control-feedback">
                                        <i className="icon-lock2 text-muted"/>
                                    </div>
                                </div>
                                {error ?
                                    <div className="text-center">
                                        <h5 className="content-group bg-danger"><i
                                            className="icon-warning22"/> {errorMsg}</h5>
                                    </div>
                                    : null}

                                <div className="form-group">
                                    <Button style={{width: "100%", background: this.state.settings.portal_pcolor, color: PT.getColorBrightness(this.state.settings.portal_pcolor) < 135 ? "white" : "#333"}}
                                            onClick={this.login}>{ST.get("login_login_now", "global")} <i
                                        className="icon-arrow-right14 position-right"/></Button>
                                </div>
                                <div className={""} dangerouslySetInnerHTML={{__html:this.state.settings.portal_login_footer}} />

                            </div>


                        </div>

                    </div>

                </div>
            );
        }
    }
}
