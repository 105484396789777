import React, {Component} from 'react';
import Dealer from "./Dealer";
import Chat from "./Chat";
import {getTagContent} from "./XMLTools";
import {Button, Col, Modal, Row} from "react-bootstrap";
import axios from "axios";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import {BubbleLoader} from "react-css-loaders";
import PaymentGateway from "../B2B/documents/PaymentGateway";
import successGif from "../images/checked.png";
import RelatedPanel from "./RelatedPanel";

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        let documentDOM = new DOMParser().parseFromString(props.documentXML, "text/xml");
        //console.log("intr: +"+getTagContent(documentDOM, props.document + "_intrauser_id2_true_id")+"+");
        this.state = {
            idIntrauser: getTagContent(documentDOM, props.document + "_intrauser_id2_true_id"),
            documentDOM: documentDOM,
            showPayment: false,
            pbsVisible: true,
            canEdit: false,
            showModalConfirm: false,
            showModalPrompt: false,
            showOrder: false,
            showCancelOrder: false,
            approved: false,
            orderData: [], orderDataLoaded: false,
            paymentResult: props.paymentResult,
            showPaymentSuccess: false
        }
    }

    static getDerivedStateFromProps(newProps, newState) {
        if (newProps.paymentResult === newState.paymentResult) {
            return null;
        } else {
            newState.paymentResult = newProps.paymentResult;
            if (newProps.paymentResult === "failed") {
                newState.showPayment = true;
            }
            if (newProps.paymentResult === "success") {
                newState.showPaymentSuccess = true;
            }
            return newState;
        }
    }

    downloadDocumentPDF() {
        let url = "";
        let endpoint = Session.getItem("endpoint");

        switch (this.props.document) {
            default:
            case "quotation" :
                url = endpoint + "printoutQuotation?id=" + this.props.id + "&lang=0&exportType=0&exportTarget=0&intrauser_id=" + window.portalSettings.portal_intrauser_id;
                break;
            case "orderin" :
                url = endpoint + "OrderinPrintoutServlet?id=" + this.props.id + "&lang=0&exportType=0&exportTarget=0&intrauser_id=" + window.portalSettings.portal_intrauser_id;
                break;
            case "orderout" :
                url = endpoint + "OrderoutPrintoutServlet?id=" + this.props.id + "&lang=0&exportType=0&exportTarget=0&intrauser_id=" + window.portalSettings.portal_intrauser_id;
                break;
            case "invoice" :
                url = endpoint + "printoutInvoice?id=" + this.props.id + "&lang=0&exportType=0&exportTarget=0&intrauser_id=" + window.portalSettings.portal_intrauser_id;
                break;
            case "warehouse" :
                url = endpoint + "WarehousePrintoutServlet?warehouse_id=" + this.props.id + "&lang=0&exportType=0&exportTarget=0&intrauser_id=" + window.portalSettings.portal_intrauser_id;
                break;
        }

        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idDocument: this.props.id,
            document: this.props.document,
            action: "PREPARE_REPORT"
        })
            .then(() => {
                //window.open(url, "_blank")
                if (window.cordova) {
                    this.download(url)
                } else {
                    window.location.href = url;
                }
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    download(fileurl) {
        let blob = null;
        let xhr = new XMLHttpRequest();
        let mimeType="application/pdf";
        let filename="document2.pdf"
        xhr.open("GET", fileurl);
        xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
        xhr.onload = function () {
            blob = xhr.response;//xhr.response is now a blob object
            document.addEventListener("deviceready", function () {
                let storageLocation = window.cordova.file.externalDataDirectory;

                let folderPath = storageLocation;

                window.resolveLocalFileSystemURL(
                    folderPath,
                    function (dir) {
                        dir.getFile(
                            filename,
                            {
                                create: true
                            },
                            function (file) {
                                file.createWriter(
                                    function (fileWriter) {
                                        fileWriter.write(blob);

                                        fileWriter.onwriteend = function () {
                                            var url = file.toURL();
                                            window.cordova.plugins.fileOpener2.open(url, mimeType, {
                                                error: function error(err) {
                                                    console.error(err);
                                                    alert("Unable to download1");
                                                },
                                                success: function success() {
                                                    console.log("success with opening the file");
                                                }
                                            });
                                        };

                                        fileWriter.onerror = function (err) {
                                            alert("Unable to download2");
                                            console.error(err);
                                        };
                                    },
                                    function (err) {
                                        // failed
                                        alert("Unable to download3");
                                        console.error(err);
                                    }
                                );
                            },
                            function (err) {
                                alert("Unable to download4");
                                console.error(err);
                            }
                        );
                    },
                    function (err) {
                        alert("Unable to download5");
                        console.error(err);
                    }
                );
            });

        }
        xhr.send();
    }

    downloadToDevice(fileurl) {
        let blob = null;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", fileurl);
        xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
        xhr.onload = function () {
            blob = xhr.response;//xhr.response is now a blob object
            console.log(blob);
            let storageLocation = "file:///storage/emulated/0/";
            /*
            switch (device.platform) {
                case "Android":
                    storageLocation = 'file:///storage/emulated/0/';
                    break;
                case "iOS":
                    storageLocation = cordova.file.documentsDirectory;
                    break;
            }

             */
            let folderpath = storageLocation + "Download";
            let filename = "document.pdf";
            let DataBlob = blob;
            window.resolveLocalFileSystemURL(folderpath, function (dir) {
                dir.getFile(filename, {create: true}, function (file) {
                    file.createWriter(function (fileWriter) {
                        fileWriter.write(DataBlob);
                        //Download was succesfull

                    }, function (err) {
                        // failed
                        alert("Unable to download");
                    });
                });
            });
        }
        xhr.send();
    }

    togglePayment() {
        this.setState({
            showPayment: !this.state.showPayment
        })
    }

    togglePaymentSuccess() {
        this.setState({
            showPaymentSuccess: !this.state.showPaymentSuccess
        })
    }

    toggleCancelOrder = () => {
        this.setState({
            showCancelOrder: !this.state.showCancelOrder
        })
    };

    toggleOrder = () => {
        let showOrder = !this.state.showOrder;
        if (showOrder) {
            axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                params: {
                    idMandant: Session.idMandant,
                    idCompany: Session.getItem("idCompany"),
                    idContact: Session.getItem("idContact"),
                    idCurrency: Session.getItem("idCurrency"),
                    idLanguage: Session.getItem("idLanguage"),
                    priceLevel: Session.getItem("priceLevel"),
                    id: this.props.id,
                    document: this.props.document,
                    action: "GET_ITEMS_FROM_DOCUMENT"
                }
            })
                .then(response => {
                    let {data} = response;
                    this.setState({orderData: data.items, orderDataLoaded: true});
                })
                .catch(err => {
                    PT.handleError(err);
                });
            this.setState({showOrder});
        } else {
            this.setState({showOrder});
        }
    };

    submitOrder = async () => {
        //console.log(this.props);
        let items = this.state.orderData;
        for (const el of items){
            //(amount, id, idType, name, orderOur, price, vatKoef, weight, event
            let product = await  axios.get(Session.getItem("endpoint") + 'PortalServlet',
                {
                    params: {
                        idMandant: Session.idMandant,
                        idContact: Session.getItem("idContact"),
                        idCompany: Session.getItem("idCompany"),
                        idProduct: String(el.product_id),
                        idCatalogue: "0",
                        idCurrency: Session.getItem("idCurrency"),
                        idLanguage: Session.getItem("idLanguage"),
                        priceLevel: Session.getItem("priceLevel"),
                        action: "GET_PORTAL_PRODUCT"
                    }
                })
            let type=undefined;
            if(el.product_type_id && el.product_type_id>0){
                type= product.data.types.find(type=>type.id===el.product_type_id)
            }
            await PT.addToBasket(el.amount, el.product_id, el.product_type_id, product.data, type, false,undefined);


        }
        this.setState({showOrder: !this.state.showOrder},
            () => this.props.history.replace(Session.getItem("basename") + "/basket")
        );
    };

    hidePayBySquarePanel() {
        this.setState({
            pbsVisible: false
        });
    }

    approveQuotation = () => {
        this.setState({
            showModalPrompt: true
        });
    };

    approveQuotationConfirm = () => {
        axios.post(Session.getItem("endpoint") + 'PortalServlet', {
            idDocument: this.props.id,
            idMandant: Session.idMandant,
            action: "CHANGE_QUOTATION_STATE"
        })
            .then(() => {
                axios.get(Session.getItem("endpoint") + 'PortalServlet?idMandant=' + Session.idMandant + "&idDocument=" + this.props.id + "&action=GET_DOCUMENT_STATE")
                    .then(response => {
                        let {data} = response;
                        if (this.state.canEdit !== data.canEdit || this.state.approved !== data.approved) {
                            this.setState({
                                canEdit: data.canEdit,
                                approved: data.approved,
                                showModalConfirm: true,
                                showModalPrompt: false
                            });
                        }
                    }).catch(error => {
                    console.log(error);
                })
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    };

    componentDidMount() {
        if (this.props.document === "quotation" || this.props.document === "orderin") {
            axios.get(Session.getItem("endpoint") + 'PortalServlet?idMandant=' + Session.idMandant + "&document=" + this.props.document + "&idDocument=" + this.props.id + "&action=GET_DOCUMENT_STATE")
                .then(response => {
                    let {data} = response;
                    if (this.state.canEdit !== data.canEdit || this.state.approved !== data.approved) {
                        this.setState({
                            canEdit: data.canEdit,
                            approved: data.approved
                        });
                    }
                })
                .catch(error => {
                })
        }

    }

    cancelOrder = () => {
        axios.post(Session.getItem("endpoint") + "PortalServlet",
            {
                action: "CANCEL_ORDER",
                idMandant: Session.idMandant,
                id: this.props.id
            }).then(response => {
            this.toggleCancelOrder();
            this.setState({
                canEdit: false
            });
        }).catch(err => {
            console.trace(err);
        })
    };

    getActionButton() {

        let documentDom = this.state.documentDOM;
        let result = "";

        let paybysquare = getTagContent(documentDom, "paybysquare").substring(getTagContent(documentDom, "paybysquare").indexOf("Connector"));

        switch (this.props.document) {
            default:
            case "quotation" :
                if (Number(Session.getItem("portal_type")) > 0) {
                    if (this.state.canEdit) {
                        result =
                            <Row>
                                <Col md={12}>
                                    <button onClick={this.approveQuotation}
                                            className="btn bg-success btn-block btn-float btn-float-lg legitRipple"
                                            type="button"><i className="icon-check"/>
                                        <span>{ST.get("quotation_approve", "global")}</span>
                                    </button>
                                </Col>
                            </Row>;
                    } else {
                        if (this.state.approved) {
                            result =
                                <Row>
                                    <Col md={12}>
                                        <div className="btn text-success btn-block btn-float btn-float-lg legitRipple">
                                            <i className="icon-check"/>
                                            <span>{ST.get("quotation_approved", "document")}</span>
                                        </div>
                                    </Col>
                                </Row>;
                        }
                    }
                } else {
                    result = null;
                }
                break;
            case "orderin" :
                if (Number(Session.getItem("portal_type")) > 1 && Session.getItem("logged") === "true") {
                    result = <span>
                        <Row>
                            <Col md={12}>
                                <button onClick={this.toggleOrder}
                                        className="btn bg-success btn-block btn-float btn-float-lg legitRipple"
                                        type="button"><i className="icon-cart-add2"/>
                                    <span>{ST.get("order_again", "document")}</span>
                                </button>
                            </Col>
                            <Modal size={"lg"}
                                   show={this.state.showOrder} onHide={this.toggleOrder}>
                                <Modal.Header className={"bg-primary"}>
                                    <Modal.Title>{ST.get("order_again", "document")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {ST.get("order_again_text", "document")}
                                    <table className="table  items-table">
                                        <thead>
                                        <tr>
                                            <th style={{minWidth: "200px"}}>{ST.get("product_name", "product")}</th>
                                            <th style={{
                                                maxWidth: "180px",
                                                minWidth: "66px"
                                            }}>{ST.get("product_order_our", "product")}</th>
                                            <th style={{
                                                maxWidth: "180px",
                                                minWidth: "66px"
                                            }}>{ST.get("amount", "global")}</th>
                                            {Boolean(window.portalSettings.show_prices) ? (
                                                <th style={{maxWidth: "180px", minWidth: "66px"}}
                                                    className={"text-right"}>{ST.get("total_pc_excl_vat", "global")}</th>
                                            ) : null}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.orderDataLoaded ?
                                            this.state.orderData.length > 0 ?
                                                this.state.orderData.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.orderOur}</td>
                                                        <td className={"text-right"}>{item.amount}</td>
                                                        {Boolean(window.portalSettings.show_prices) ? (
                                                            <td className={"text-right"}>{Number(item.price).toFixed(2)}&nbsp;{Session.getItem("codeCurrency")}</td>
                                                        ) : null}
                                                    </tr>;
                                                }) :
                                                <tr>
                                                    <td className={"center"}
                                                        colSpan={4}>{ST.get("no_results", "global")}</td>
                                                </tr>
                                            :
                                            <tr>
                                                <td colSpan={4}><BubbleLoader
                                                    color={window.portalSettings.portal_pcolor}/></td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.submitOrder}
                                            className={"mt-10 btn btn-success"}>{ST.get("confirm", "global")}</Button>
                                    <Button onClick={this.toggleOrder}
                                            className={"mt-10 btn-default btn-danger"}>{ST.get("cancel", "global")}</Button>
                                </Modal.Footer>
                            </Modal>
                        </Row>
                        {this.state.canEdit ? (<Row>
                            <Col md={12}>
                                <button onClick={this.toggleCancelOrder}
                                        className="btn bg-warning btn-block btn-float btn-float-lg legitRipple"
                                        type="button"><i className="icon-cross"/>
                                    <span>{ST.get("cancel_order", "document")}</span>
                                </button>
                            </Col>
                            <Modal size={"lg"}
                                   show={this.state.showCancelOrder} onHide={this.toggleOrder}>
                                <Modal.Header className={"bg-primary"}>
                                    <Modal.Title>{ST.get("cancel_order", "document")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {ST.get("cancel_order_text", "document")}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.cancelOrder}
                                            className={"mt-10 btn btn-success"}>{ST.get("yes", "global")}</Button>
                                    <Button onClick={this.toggleCancelOrder}
                                            className={"mt-10 btn-default btn-danger"}>{ST.get("no", "global")}</Button>
                                </Modal.Footer>
                            </Modal>
                        </Row>) : null}
                    </span>;
                } else {
                    result = <span/>;
                }
                break;
            case "orderout" :
                result = <span/>;
                break;
            case "invoice" :
                let rest = parseFloat(getTagContent(documentDom, "invoice_part_rest").replace(",", ".").replace(" ", ""));

                if (rest > 0) {
                    result =
                        <Row>
                            <Col md={12}>
                                <button onClick={this.togglePayment.bind(this)}
                                        className="btn bg-success btn-block btn-float btn-float-lg legitRipple"
                                        type="button"><i className="icon-wallet"/>
                                    <span>{ST.get("pay", "global")}</span>
                                </button>
                            </Col>
                            <div>
                                <Modal size="lg"
                                       aria-labelledby="contained-modal-title-vcenter"
                                       show={this.state.showPayment} onHide={this.togglePayment.bind(this)}>
                                    <Modal.Header className={"bg-primary"}>
                                        <Modal.Title>{ST.get("invoice_payment", "global")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            {this.state.paymentResult === "failed" ?
                                                <Col md={12}>
                                                    <div className="alert bg-danger alert-styled-left"><span
                                                        className="text-semibold">{ST.get("payment_failed", "document")}</span>
                                                    </div>
                                                </Col> : null}
                                            <Col md={this.state.pbsVisible && paybysquare !== '' ? 8 : 12}>
                                                <div className="panel">
                                                    <div className="panel-heading no-padding-bottom">
                                                        <h6 className="panel-title">{ST.get("transfer_to_account", "global")}</h6>
                                                    </div>
                                                    <div className="panel-body">
                                                        {getTagContent(documentDom, "mandant_bank_account") !== '' ?
                                                            <Row>
                                                                <Col
                                                                    md={5}>{getTagContent(documentDom, "account_number")}:</Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "mandant_bank_account")}</Col>
                                                            </Row>
                                                            : null}
                                                        {getTagContent(documentDom, "mandant_iban") !== '' ?
                                                            <Row>
                                                                <Col md={5}>{getTagContent(documentDom, "iban")}:</Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "mandant_iban")}</Col>
                                                            </Row>
                                                            : null}
                                                        {getTagContent(documentDom, "mandant_swift") !== '' ?
                                                            <Row>
                                                                <Col md={5}>{getTagContent(documentDom, "swift")}:</Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "mandant_swift")}</Col>
                                                            </Row>
                                                            : null}
                                                        {getTagContent(documentDom, "invoice_vs") !== '' ?
                                                            <Row>
                                                                <Col
                                                                    md={5}>{getTagContent(documentDom, "variableSymbol")}:</Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "invoice_vs")}</Col>
                                                            </Row>
                                                            : null}
                                                        {getTagContent(documentDom, "invoice_ks") !== '' ?
                                                            <Row>
                                                                <Col
                                                                    md={5}>{getTagContent(documentDom, "constantSymbol")}: </Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "invoice_ks")}</Col>
                                                            </Row>
                                                            : null}
                                                        {getTagContent(documentDom, "invoice_ss") !== '' ?
                                                            <Row>
                                                                <Col
                                                                    md={5}>{getTagContent(documentDom, "specificSymbol")}: </Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "invoice_ss")}</Col>
                                                            </Row>
                                                            : null}
                                                        {getTagContent(documentDom, "invoice_part_rest") !== '' ?
                                                            <Row>
                                                                <Col
                                                                    md={5}>{getTagContent(documentDom, "total_to_pay")}:</Col>
                                                                <Col md={7}
                                                                     className={"text-semibold"}>{getTagContent(documentDom, "invoice_part_rest")}</Col>
                                                            </Row>
                                                            : null}
                                                    </div>
                                                </div>
                                                <PaymentGateway id={this.props.id}/>
                                            </Col>
                                            {paybysquare !== '' && this.state.pbsVisible ?
                                                <Col md={4}>
                                                    <div className="panel">
                                                        <div className="panel-heading no-padding-bottom">
                                                            <h6 className="panel-title">Pay By Square</h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            <img className={"img-responsive"}
                                                                 src={Session.getItem("endpoint") + paybysquare}
                                                                 alt={"pay by square"}
                                                                 onError={this.hidePayBySquarePanel.bind(this)}
                                                            />
                                                        </div>

                                                    </div>
                                                </Col>
                                                : null
                                            }
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={this.togglePayment.bind(this)}
                                                className={"mt-10 btn-default"}>{ST.get("close", "global")}</Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </Row>
                } else {
                    result = <Row>
                        <Col md={12}>
                            <button onClick={this.togglePayment.bind(this)}
                                    className="btn bg-success btn-block btn-float btn-float-lg legitRipple"
                                    type="button"><i className="icon-check2"/>
                                <span>{ST.get("invoice_date_paid_short", "global")}</span>
                            </button>
                        </Col>
                    </Row>
                }
                break;
            case "warehouse" :
                result = <span/>;
                break;
        }
        return (result);
    }

    render() {
        let {id, document, settings} = this.props;

        return (
            <div className="sidebar sidebar-opposite sidebar-default document-sidebar">
                <div className="sidebar-content">

                    <div className="row">
                        <div className="col-xs-12">
                            <button onClick={() => this.downloadDocumentPDF()}
                                    className="btn bg-danger-800 btn-block btn-float btn-float-lg legitRipple"
                                    type="button">
                                <i className="icon-file-pdf"/> <span>{ST.get("global_pdf_download", "global")}</span>
                            </button>
                        </div>
                    </div>
                    {Number(Session.getItem("portal_type")) === 2 ?
                        <RelatedPanel id={id} source={document}/> : null}
                    {this.state.idIntrauser != 0 && this.state.idIntrauser != '' ?
                        <Dealer id={this.state.idIntrauser}/> : null}

                    {settings[document + "_allow_chat"] && Number(Session.getItem("portal_type")) > 0 ? (
                        <Chat id={id} document={document} settings={settings}/>) : ""}

                    {this.getActionButton()}

                </div>
                <Modal size="md"
                       aria-labelledby="contained-modal-title-vcenter"
                       show={this.state.showModalConfirm} onHide={() => this.setState({showModalConfirm: false})}>
                    <Modal.Header style={{background: settings.portal_pcolor}}>
                        <Modal.Title>
                            <label
                                style={{color: (PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}}
                                className="text-semibold">
                                {ST.get("quotation_approved", "document")}
                            </label>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-15">
                        <Row>
                            <Col md={12}>
                                <div className="btn-group pull-right">
                                    <Button onClick={() => this.setState({showModalConfirm: false})}
                                            className="btn btn-primary ">{ST.get("close", "global")}</Button>
                                </div>
                            </Col>

                        </Row>
                    </Modal.Body>

                </Modal>
                <Modal size="md"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered={"true"} show={this.state.showModalPrompt}
                       onHide={() => this.setState({showModalPrompt: false})}>
                    <Modal.Header style={{background: settings.portal_pcolor}}>
                        <Modal.Title>
                            <label
                                style={{color: (PT.getColorBrightness(settings.portal_pcolor) < 135 ? "white" : "#333")}}
                                className="text-semibold">
                                {ST.get("quotation_approve", "global")}?
                            </label>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-15">
                        <Row>
                            <Col md={12}>
                                <div className="btn-group pull-right">
                                    <Button onClick={this.approveQuotationConfirm}
                                            className="btn btn-primary ">{ST.get("yes", "global")}</Button>
                                    <Button onClick={() => this.setState({showModalPrompt: false})}
                                            className="btn btn-default ">{ST.get("no", "global")}</Button>
                                </div>
                            </Col>

                        </Row>
                    </Modal.Body>

                </Modal>
                <Modal size="lg"
                       aria-labelledby="contained-modal-title-vcenter"
                       show={this.state.showPaymentSuccess} onHide={this.togglePaymentSuccess.bind(this)}>
                    <Modal.Header className={"bg-success"}>
                        <Modal.Title>{ST.get("invoice_payment", "global")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={2}>
                                <img src={successGif} className={"img-responsive"}/>
                            </Col>
                            <Col md={10}>
                                <h2 style={{marginLeft: "20px"}}>{ST.get("payment_success", "document")}</h2>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.togglePaymentSuccess.bind(this)}
                                className={"mt-10 btn-default"}>{ST.get("close", "global")}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
